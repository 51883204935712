<template>
  <echarts-com v-loading="loading" id="userPreference-vehiclePreference" :options="options"/>
</template>

<script>

import {formatRatio} from "@/utils/util"

export default {
  components: {
    echartsCom: () => import("@/components/echartsCom")
  },
  data() {
    return {
      options: {},
      loading: false
    }
  },
  created() {
    this.getOptions()
  },
  methods: {
    async getOptions() {
      try {
        this.loading = true;
        let res = await this.$http.post("/statistics/vehicle/preference", {});
        if (res.code === 0) {
          let legend_data = [[],[]]
          let datas = [
            res.data.colors,
            res.data.types,
          ].map((list, index) => {
            let data = []
            for (let item of list) {
              data.push({
                name: item.typeName,
                value: formatRatio(item.percentage),
                type: index === 0 ? "颜色" : "类型"
              })
              legend_data[index].push(item.typeName)
            }
            return data
          })

          this.$set(this, "options", {
            tooltip: {
              trigger: 'item',
              formatter(params){
                return `${params.marker} ${params.data.type}(${params.data.name}): ${params.data.value}%`
              }
            },
            legend: [
              {
                left: "35%",
                top: '15%',
                data:legend_data[0],
                textStyle:{
                  color:"white"
                }
              },
              {
                left: "35%",
                bottom: '20%',
                data:legend_data[1],
                textStyle:{
                  color:"white"
                }
              }
            ],
            series: datas.map((item, index) => {
              let top = index * 50;
              return {
                type: 'pie',
                radius: ['65%', '85%'],
                top: top + '%',
                height: '50%',
                left: "0",
                width: "150",
                label: {
                  show: true,
                  position: 'center',
                  color: 'white',
                  formatter(e) {
                    return `汽车${e.data.type}`
                  }
                },
                data: item
              }
            })
          })
        } else {
          this.$message.error(res.msg);
        }
        this.loading = false;
      } catch (err) {
        console.log(err)
        this.$message.error("车辆偏好分析获取失败");
        this.loading = false;
      }
    }
  }
}
</script>

